import Highway from '../web_modules/@dogstudio/highway.js';

class Swap extends Highway.Transition {
  in({ from, to, done }) {
    if (from.hasAttribute('data-post') && to.hasAttribute('data-home')) {
      let homeDelay = setTimeout(() => {
        from.remove();
        done();
      }, 400);
    } else {
      from.remove();
      done();
    }
  }

  out({ from, done }) {
    done();
  }
}

export default Swap;