import Highway from '../web_modules/@dogstudio/highway.js';
import '../web_modules/lazysizes.js';
import Swap from './transition-swap.js';
import { prefetch } from "../web_modules/quicklink.js";

const H = new Highway.Core({
  transitions: {
    default: Swap
  }
});

const container = document.getElementById('container');
const pageWrapper = document.getElementById('page-wrapper');
const navLinks = document.querySelectorAll('.nav__artist-link');
const introEl = document.getElementById('intro');
const introStickers = introEl.querySelectorAll('.intro__sticker-item');

function shuffle(array) {
  var m = array.length, t, i;

  while (m) {

    i = Math.floor(Math.random() * m--);

    t = array[m];
    array[m] = array[i];
    array[i] = t;
  }

  return array;
}

let pageState = {
  intro_complete: '',
  curr_type: '',
  curr_url: '',
}

let navState = {
  dest_url: '',
  dest_type: '',
}

function setActiveLink(links, currentPathname) {
  const normalizedPathname = currentPathname.slice(-1) == '/' ? currentPathname.substring(0, currentPathname.length - 1) : currentPathname;

  links.forEach(link => {
    if (normalizedPathname == link.getAttribute('data-href')) {
      link.setAttribute('href', '/');
      link.classList.add('--active');
    } else {
      link.setAttribute('href', link.getAttribute('data-href'));
      link.classList.remove('--active');
    }
  });
}

function initPageState(ps) {
  const body = document.getElementById('body');

  ps.curr_type = [...body.classList].map(classItem => {
    if (classItem.startsWith('page--')) {
      return classItem;
    }
  }).toString();

  ps.intro_complete = false;
}

function fiftyPctRandom() {
  return Math.random() >= 0.5;
}

function randomValPlusOrMinus(max) {
  return Math.floor((fiftyPctRandom() ? -1 : 1) * (Math.random() * max))
}

function intro(images, ps) {
  shuffle([...images]).forEach((image, index) => {
    if ((Math.random() >= 0.35 && index < 16) || index == 0) {
      const imageTag = image.querySelector('.intro__sticker-image')

      imageTag.setAttribute('src', imageTag.getAttribute('data-src'));
      imageTag.setAttribute('srcset', imageTag.getAttribute('data-srcset'));
      
      let randomPoint

      if (window.innerHeight > window.innerWidth) {
        randomPoint = {
          x: randomValPlusOrMinus(40),
          y: randomValPlusOrMinus(35)
        }
      } else {
        randomPoint = {
          x:  randomValPlusOrMinus(38),
          y: randomValPlusOrMinus(24)
        }
      }

      requestAnimationFrame(() => {
        imageTag.decode().then(() => {
          image.style.cssText = `
            transition-delay: ${Math.random() * 1.25}s; 
            z-index: ${fiftyPctRandom() ? 0 : 2};
            transform: translate3d(${randomPoint.x}vw, ${randomPoint.y}vh, 0);`

          image.classList.add('intro__sticker-item--selected');
        })
      })
    }
  });

  let introTimer = setTimeout(() => {
    body.classList.add('intro--complete');
    ps.intro_complete = true;

    introEl.addEventListener('transitionend', () => introEl.remove());
  }, 2200);
}

function updatePageState(event, ps, ns) {
  ns.dest_url = new URL(event.location.href);
  ns.dest_type = ns.dest_url.pathname == '/' ? 'page--home' : 'page--post'; //don't like this line

  if (ns.dest_type != ps.curr_type) {
    body.classList.add(`to-${ns.dest_type}`);
    pageWrapper.style.cssText = 'will-change: width;';

    function afterAnimation() {
      body.classList.remove(`to-${ns.dest_type}`);
      body.classList.remove(ps.curr_type);

      ps.curr_type = ns.dest_type;

      body.classList.add(ps.curr_type);

      pageWrapper.style.cssText = '';
    }

    H.on('NAVIGATE_END', afterAnimation);
  } 
}

window.addEventListener('DOMContentLoaded', () => {
  initPageState(pageState);
  intro(introStickers, pageState);
});

window.addEventListener('load', () => {
  setActiveLink(navLinks, window.location.pathname);
  const pageLinks = pageWrapper.querySelectorAll('.nav__artist-link')  

  pageLinks.forEach(link => {    
      prefetch(link.href)
      .then(res => {
        link.addEventListener('mouseover', event => {  
          if (link.getAttribute('data-preloaded-image') != true) {
            let assetUrl = link.getAttribute('data-preload-image');
  
            let assetLink = document.createElement(`link`);
  
            assetLink.rel = `preload`;
            assetLink.as = `image`;
            assetLink.href=`${assetUrl}?w=1200&fit=max&auto=format 1200w`
            assetLink.setAttribute('imagesrcset', `
              ${assetUrl}?w=200&fit=max&auto=format 200w, 
              ${assetUrl}?w=400&fit=max&auto=format 400w,
              ${assetUrl}?w=600&fit=max&auto=format 600w,
              ${assetUrl}?w=800&fit=max&auto=format 800w,
              ${assetUrl}?w=900&fit=max&auto=format 900w,
              ${assetUrl}?w=1000&fit=max&auto=format 1000w,
              ${assetUrl}?w=1100&fit=max&auto=format 1100w,
              ${assetUrl}?w=1200&fit=max&auto=format 1200w,
              ${assetUrl}?w=1400&fit=max&auto=format 1400w,
              ${assetUrl}?w=1600&fit=max&auto=format 1600w,
              ${assetUrl}?w=1800&fit=max&auto=format 1800w
            `)
            assetLink.setAttribute('imagesizes',`
              (min-width: 900px) calc(100vw - 352px),
              (min-width: 700px) calc(100vw - 268px), 
              100vw
            `)
  
            link.setAttribute('data-preloaded-image', true)
            document.head.appendChild(assetLink);
          }
        }, {
          once: true
        });
      })
      .catch(error => {
        console.log(error)
      })
  });
});

H.on('NAVIGATE_IN', event => {
  updatePageState(event, pageState, navState)
})

H.on('NAVIGATE_END', event => {
  console.log(event)

  setActiveLink(navLinks, navState.dest_url.pathname)
  if (window.innerWidth < 700) {
    pageWrapper.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  container.scrollTo(0, 0);
})

const matchWidth = window.matchMedia("(max-width: 700px)");
matchWidth.addEventListener('change', event => {
  if (matchWidth.matches) {
    pageWrapper.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
})